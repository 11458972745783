import React, {Component} from 'react';
import {useHistory} from "react-router-dom";
import {Range, createSliderWithTooltip} from 'rc-slider';
import 'rc-slider/assets/index.css';
import Tree from 'rc-tree';
import {SIZE_TO_TEXT} from './TestbedCard';
import {useAsyncDebounce} from "../utils";

const MEMORY_MARKS = {
    '0': '0',
    '1': '512 MB',
    '2': '1 GB',
    '3': '2 GB',
    '4': '4 GB',
    '5': '8 GB',
    '6': '16 GB',
    '7': '32 GB',
    '8': '64 GB',
    '9': '128 GB',
    '10': <span>&infin;</span>
};

const MEMORY_VALUES = {
    '0': null,
    '1': 0.5,
    '2': 1,
    '3': 2,
    '4': 4,
    '5': 8,
    '6': 16,
    '7': 32,
    '8': 64,
    '9': 128,
    '10': null
};


const DISK_MARKS = {
    '0': '0',
    '1': '10 GB',
    '2': '25 GB',
    '3': '50 GB',
    '4': '100 GB',
    '5': '200 GB',
    '6': '500 GB',
    '7': '1 TB',
    '8': '2 TB',
    '9': '4 TB',
    '10': <span>&infin;</span>
};

const DISK_VALUES = {
    '0': null,
    '1': 10,
    '2': 25,
    '3': 50,
    '4': 100,
    '5': 200,
    '6': 500,
    '7': 1000,
    '8': 2000,
    '9': 4000,
    '10': null
};

export default function TestbedFilters(props) {

    const {filters, activeFilters, updateFilters} = props;
    const {features} = filters;

        const debounceUpdateFilters = useAsyncDebounce((key, newFilters) => {
        updateFilters(key,newFilters);
    } , 250);

    const history = useHistory();

    const updateCategory = (e) => {
        if (e.target.checked) {
            if (e.target.value !== activeFilters['category']) {

                if (e.target.value) {
                    history.push(`/by_category/${e.target.value}`);
                } else {
                    history.push('/');
                }
                // updateFilters('category', e.target.value);
            }
        }
    }

    let featuresFilters = (<span><small>No feature-filters are available in this category.</small></span>);

    if (filters['features'].length > 0) {
        featuresFilters = filters['features'].map(featureFilter => (
            <span key={featureFilter.id}>
                    <input type="checkbox" id={featureFilter.id}
                           checked={activeFilters['features'].includes(featureFilter.id)}
                           onChange={e => {
                               const checked = e.target.checked;
                               if (checked) {
                                   updateFilters('features', [featureFilter.id, ...activeFilters['features']]);
                               } else {
                                   updateFilters('features',
                                       activeFilters['features'].filter(active_prop => active_prop !== featureFilter.id));
                               }
                           }}/>
                               <label htmlFor={featureFilter.id}>{featureFilter.name}
                               </label><br/>
                    </span>
        ));
    }

    return (<div>
        <h4>Category</h4>

        <div style={{backgroundColor: 'white', padding: '12px 0 12px 12px', marginBottom: 24}}>

            {filters['categories'].map(category => (<span key={category.id}>
                    <input type="radio" id={category.id}
                           checked={activeFilters['category'] === category.id}
                           value={category.id} onChange={updateCategory}/>
                            <label htmlFor={category.id}>{category.name}</label><br/></span>
                )
            )}

            {/*<input type="radio" id="all" checked={!activeFilters['category'] ||*/}
            {/*activeFilters['category'] === "all"} onChange={updateCategory} value=""/>*/}
            {/*<label htmlFor="all">Show All</label>*/}
        </div>

        <h4>Filters</h4>
        <div className="filter">
            <h6>Features</h6>
            <div style={{backgroundColor: 'white', padding: '12px 0 12px 12px'}}>
                {featuresFilters}
            </div>
        </div>

        <div className="filter">
            <h6>Memory</h6>
            <div style={{backgroundColor: 'white', padding: '12px', 'height': 250}}>
                <Range vertical reverse allowCross={false} min={0} max={10} marks={
                    MEMORY_MARKS
                } defaultValue={[0, 10]} onChange={([min, max]) => {
                    debounceUpdateFilters('memory', [MEMORY_VALUES[min], MEMORY_VALUES[max]]);
                }} style={{width: 'initial'}}/>
            </div>
        </div>

        <div className="filter">
            <h6>Disk</h6>
            <div style={{backgroundColor: 'white', padding: '12px', 'height': 250}}>
                <Range vertical reverse allowCross={false} min={0} max={10} marks={
                    DISK_MARKS
                } defaultValue={[0, 10]} onChange={([min, max]) => {
                    console.log(min, max);
                    debounceUpdateFilters('disk', [DISK_VALUES[min], DISK_VALUES[max]]);
                }} style={{width: 'initial'}}/>
            </div>
        </div>
    </div>);
}
