import AuthorizeSpeaksFor from "./components/AuthorizeSpeaksFor";
const $ = require("jquery");
import * as ReactDOM from "react-dom";
import React from "react";
import {
  MemberTotals,
  NewMemberStatistics,
} from "./components/MemberStatistics";
import ExploreTestbeds from "./explore_components/ExploreTestbeds";
import ExploreResourcesPage from "./explore_components/ExploreResources";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import {
  NewProjectStatistics,
  ProjectTotals,
} from "./components/ProjectStatistics";
import "htmx.org";

const queryClient = new QueryClient();

window.$ = $;
window.jQuery = $;
window.htmx = require("htmx.org");

// window.htmx.logger = function (elt, event, data) {
//   if (console) {
//     console.log(event, elt, data);
//   }
// };

function showTotalMemberStatistics(data, prettyNames, domContainer) {
  ReactDOM.render(
    <MemberTotals data={data} prettyNames={prettyNames} />,
    domContainer
  );
}

function showNewMemberStatistics(data, prettyNames, domContainer) {
  ReactDOM.render(
    <NewMemberStatistics data={data} prettyNames={prettyNames} />,
    domContainer
  );
}

function showTotalProjectStatistics(data, prettyNames, domContainer) {
  ReactDOM.render(
    <ProjectTotals data={data} prettyNames={prettyNames} />,
    domContainer
  );
}

function showNewProjectStatistics(data, prettyNames, domContainer) {
  ReactDOM.render(
    <NewProjectStatistics data={data} prettyNames={prettyNames} />,
    domContainer
  );
}

function showAuthorizeSpeaksFor(tool, csrf_token, domContainer) {
  ReactDOM.render(
    <AuthorizeSpeaksFor tool={tool} csrf_token={csrf_token} />,
    domContainer
  );
}

function collapseMenu(collapse) {
  if (collapse) {
    $("#left-sidebar").addClass("collapsed");
    $("#menu-fold-trigger").addClass("collapsed");

    $("#left-sidebar li").each((i, e) => {
      new Foundation.Tooltip($(e));
    });
  } else {
    $("#left-sidebar").removeClass("collapsed");
    $("#menu-fold-trigger").removeClass("collapsed");

    $("#left-sidebar li").each((i, e) => {
      // $(e).foundation("_destroy");
    });
  }
}

function initMenu() {
  let shouldCollapse = false;

  if (
    typeof Storage !== "undefined" &&
    window.sessionStorage.getItem("menu-collapsed") !== null
  ) {
    //user has explicitly set the menu state
    shouldCollapse = JSON.parse(
      window.sessionStorage.getItem("menu-collapsed")
    );
  } else {
    //check width of viewport instead
    const windowWidth = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
    shouldCollapse = windowWidth < 640;
  }

  if (shouldCollapse) {
    collapseMenu(shouldCollapse);
  }
}

function toggleMenu() {
  const shouldCollapse = !$("#left-sidebar").hasClass("collapsed");
  collapseMenu(shouldCollapse);

  if (typeof Storage !== "undefined") {
    window.sessionStorage.setItem("menu-collapsed", shouldCollapse);
  }
}

window.app = {
  showTotalMemberStatistics,
  showNewMemberStatistics,
  showTotalProjectStatistics,
  showNewProjectStatistics,
  showAuthorizeSpeaksFor,
  toggleMenu,
};

function showExploreTestbeds(testbeds, domContainer) {
  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename="/explore/directory">
        <ExploreTestbeds testbeds={testbeds} />
      </BrowserRouter>
    </QueryClientProvider>,
    domContainer
  );
}

function showExploreResources(categoriesAndFeatures, domContainer) {
  ReactDOM.render(
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename="/explore/resources">
        <ExploreResourcesPage categories={categoriesAndFeatures} />
      </BrowserRouter>
    </QueryClientProvider>,
    domContainer
  );
}

window.exploreApp = {
  showExploreTestbeds,
  showExploreResources,
};

$().ready(function () {
  // $(document).foundation();
  initMenu();
});
