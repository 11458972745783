import React from 'react';
import {CATEGORY_ID_TO_NAME} from "./ExploreTestbeds";

export const SIZE_TO_TEXT = {
    '1to25': "1-25 nodes",
    '25to100': '25-100 nodes',
    '100to500': '100-500 nodes',
    '500plus': '500+ nodes'
}

export default function TestbedCard(props) {

    const {
        id, activeFilters, testbed_name, owner, country, description,
        properties, site_url, documentation_url, image_url,
        allTestbedTechnologies, is_federated
    } = props;

    console.log("props", props);
    const {size, category = [], technologiesTree: technologies = [], properties: tb_properties = []} = properties;

    const activeTechnologies = technologies.filter(t => activeFilters['technologies'].includes(t.key));
    const inactiveTechnologies = technologies.filter(t => !activeFilters['technologies'].includes(t.key));
    const missingTechnologyKeys = activeFilters['technologies'].filter(act_t => !technologies.find(t => act_t === t.key));

    const locationMismatch = activeFilters['locations'].length > 0 && !activeFilters['locations'].includes(country);

    const allTechnologies =
        [...activeTechnologies.map(t => <strong key={t.key}>{t.name}</strong>),
            ...inactiveTechnologies.map(t => <span key={t.key}>{t.name}</span>),
            ...missingTechnologyKeys.filter(tKey => tKey in allTestbedTechnologies)
                .map(tKey => <span key={tKey} className="missing-filter">{allTestbedTechnologies[tKey].name}</span>)
        ]
            .reduce((prev, curr) => [prev, ', ', curr]);

    const activeProperties = tb_properties.filter(t => activeFilters['properties'].includes(t));
    const inactiveProperties = tb_properties.filter(t => !activeFilters['properties'].includes(t));
    const missingProperties = activeFilters['properties'].filter(act_p => !tb_properties.includes(act_p));
    const allProperties = [
        ...activeProperties.map(p => <strong key={p}>{p}</strong>),
        ...inactiveProperties,
        ...missingProperties.map(p => <span key={p} className="missing-filter">{p}</span>)]
        .reduce((prev, curr) => [prev, ', ', curr]);

    {/*,*/
    }
    let categoryError;
    if (category && !category.includes(activeFilters['category']) && category.length) {
        categoryError = (<p><em><i className="fal fa-tags"></i> Categories: </em>
            {[...category.map(c => CATEGORY_ID_TO_NAME[c]),
                (<span key="missing"
                       className="missing-filter">{CATEGORY_ID_TO_NAME[activeFilters['category']]}</span>)]
                .reduce((prev, curr) => [prev, ', ', curr])}
        </p>);
    } else {

    }

    return (<div className="card testbed-card">
        <div className="card-section">
            <img src={image_url}
                 style={{float: 'right', maxWidth: 250, height: 'auto'}} alt=""/>

            <h5>{testbed_name}</h5>
            <p>{owner}, <span className={`${locationMismatch ? "missing-filter":""}`}>{country}</span></p>

            {categoryError}
            <p><em><i className="fal fa-signal-alt"></i> Size:</em>&nbsp;
                <span className={activeFilters['sizes'].length && !activeFilters['sizes'].includes(size) ?  'missing-filter': ''}>{SIZE_TO_TEXT[size] || '?'}</span></p>
            <p><em><i className="fal fa-shapes"></i> Technologies:</em> {allTechnologies}
            </p>
            <p><em><i className="fal fa-ticket"></i> Properties:</em> {allProperties}
            </p>
            <p><em style={{fontVariant: 'small-caps'}}>Description</em><br/>
                {description}</p>

            <p><em><i className="fal fa-cubes"></i> Is Federated:</em>&nbsp;
                <span className={activeFilters['show_federated'] !== null && activeFilters['show_federated'] !==  is_federated ?  'missing-filter': ''}>
                    {is_federated ? "Yes": "No"}
                </span>
            </p>

            <a href={site_url} className="button">
                <i className="fal fa-info-circle"></i> Learn more</a>
            <a href={`/explore/contact/${id}`} className="button secondary hollow">
                <i className="fal fa-comments"></i> Ask a question</a>
            <a href={documentation_url} className="button secondary hollow">
                <i className="fal fa-book"></i> Technical documentation</a>

        </div>
    </div>);

}
