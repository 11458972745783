import React, {useState, Fragment} from "react";
import {useRouteMatch} from "react-router-dom";
import TestbedFilters from "./TestbedFilters";
import TestbedCards from "./TestbedCards";

export const CATEGORY_ID_TO_NAME = {
    "wired": "Wired",
    "wireless": "Wireless",
    "4g": "4G",
    "5g": "5G",
    "6g": "6G",
    "edge": "Edge",
    "iot": "IoT",
    "bigdata": "Big Data",
    "openflow": "OpenFlow",
    "cloud": "Cloud",
    "sdn": "SDN",
    "ai": "AI",
    "cognitiveradio": "Cognitive Radio",
    "hpc": "HPC",
    "gpu": "GPU",
    "multimedia": "Multimedia",
    "optical": "Optical"
};

const TECH_REGEX = /\[(?<toplevel>.*)\] (?<techname>.*)/;

export default function ExploreTestbeds(props) {
    const {testbeds} = props;

    //convert technologies in testbeds to parent+key values
    testbeds.forEach(tb => {
        tb.properties['technologiesTree'] = tb.properties['technologies'].map(tech => {
            const match = TECH_REGEX.exec(tech);

            if (match) {
                return {key: tech, parent: match.groups.toplevel, name: match.groups.techname};
            } else {
                return {key: tech, parent: null, name: tech};
            }
        });
    });

    const technologiesMap = {};
    const propertiesSet = new Set();
    const locationsSet = new Set();

    testbeds.forEach(tb => {
        tb.properties['technologiesTree'].forEach(t => technologiesMap[t.key] = t);
        tb.properties['properties'].forEach(p => propertiesSet.add(p));

        tb.is_federated = tb.properties['is_federated'] === undefined || tb.properties['is_federated'];
        locationsSet.add(tb.country);
    });

    const filters = {
        technologies: Object.values(technologiesMap),
        properties: [...propertiesSet],
        locations: [...locationsSet]
    };

    const [activeFilters, updateFiltersState] = useState({
        category: undefined,
        technologies: [],
        properties: [],
        locations: [],
        sizes:[],
        show_federated: null, //null = all
    });
    const updateFilters = (newFilters) => updateFiltersState({...activeFilters, ...newFilters});

    const match = useRouteMatch('/:category');

    let categoryFriendlyName;
    if (match && match.params['category'] && CATEGORY_ID_TO_NAME.hasOwnProperty(match.params['category'])) {
        activeFilters['category'] = match.params['category'];
        categoryFriendlyName = CATEGORY_ID_TO_NAME[match.params['category']];
    } else {
        activeFilters['category'] = undefined;
        categoryFriendlyName = "All";
    }

    return (
        <Fragment>
            <aside id="sidebar-filters" className="filters-aside">
                <TestbedFilters filters={filters} activeFilters={activeFilters} updateFilters={updateFilters}
                                testbeds={testbeds}
                />
            </aside>

            <main className="main-content">
                <div className="main-content-wrapper">
                    <h2>{categoryFriendlyName} testbeds</h2>
                    <p>Use the filters to find a testbed that matches your requirements best.</p>

                    <TestbedCards activeFilters={activeFilters} testbeds={testbeds} technologies={technologiesMap}/>

                </div>
            </main>
        </Fragment>);

}
