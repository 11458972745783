const exploreResourcesApiBaseUrl = "/explore/resources/api/v1.0/";

class ExploreResourcesService {
    doApiCall(url, options = {}, params = {}) {
        const fullUrl = exploreResourcesApiBaseUrl + url;

        if (!('headers' in options)) {
            options['headers'] = {};
        }
        options['headers']['X-CSRF-TOKEN'] = csrf_token;

        return fetch(fullUrl, {
            ...options, credentials: 'include'
        }).then(function (response) {
            if (!response.ok) {
                let bodyText = 'ERROR: not fetched';
                response.body.text().then(t => bodyText = t);
                throw new APIError(response.status, response.statusText, bodyText);
            }
            return response;
        }).catch(error => {
            if (error.name === "APIError") {
                throw error;
            }
            if (error.name === "TypeError" && error.message === "Failed to fetch") {
                throw new APIError(403, "Failed to fetch, probably due to CORS");
            }
            throw new APIError(500, "Failed to fetch: " + error.message);
        }).then(response => response.json());
    }


    async getResourceCounts(filters, offset, limit) {
        const data = new FormData();

        if (filters['category']) {
            data.append("category", filters['category']);
        }
        data.append('features', filters['features']);
        if (filters['memory']) {
            data.append('memory', filters['memory']);
        }
        if (filters['disk']) {
            data.append('disk', filters['disk']);
        }

        return this.doApiCall("find", {method: 'POST', body: data});
    }
}

class APIError extends Error {
    constructor(statusCode, statusText, bodyText, ...params) {
        // Pass remaining arguments (including vendor specific ones) to parent constructor
        super(...params);

        // Maintains proper stack trace for where our error was thrown (only available on V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, APIError);
        }

        this.name = 'APIError';
        // Custom debugging information
        this.statusCode = statusCode;
        this.statusText = statusText;
        this.bodyText = bodyText;
        this.date = new Date();
    }

    toErrorText() {
        return `${this.statusCode} (${this.statusText}): ${this.bodyText}`;
    }

    toString() {
        return `[APIError ${this.statusCode} (${this.statusText}): ${this.bodyText}]`;
    }
}


export default ExploreResourcesService;
export {APIError};
