import React, {Fragment} from 'react';
import TestbedCard from "./TestbedCard";


export default function TestbedCards(props) {
    const {testbeds, activeFilters, technologies} = props;

    function countMismatch(tb) {
        let count = 0;

        if (activeFilters['category']) {
            if (!tb.properties['category'].includes(activeFilters['category']))
                count++;
        }
         for (const location of activeFilters['locations']) {
            if (!tb['country'].includes(location))
                count++;
        }


        for (const property of activeFilters['properties']) {
            if (!tb.properties['properties'].includes(property))
                count++;
        }

        for (const technology of activeFilters['technologies']) {
            if (!tb.properties['technologies'].includes(technology))
                count++;
        }

        if(activeFilters['sizes'].length && !activeFilters['sizes'].includes(tb.properties['size'])){
            count++;
        }

        if(activeFilters['show_federated'] !== null && tb.properties['is_federated'] !== activeFilters['show_federated']){
            count++;
        }

        return count;
    }

    const counts = testbeds.map(tb => countMismatch(tb));

    const minimumMismatches = Math.min(...counts);
    const exactMatches = counts.reduce((prev, curr) => prev + (curr === 0 ? 1 : 0), 0);

    const filteredTestbeds = testbeds.filter((tb, idx) => counts[idx] === minimumMismatches);

    return (
        <Fragment>
            <p>Your current selection matches {exactMatches} testbeds.&nbsp;
                {exactMatches === 0 ? (<span>Showing partially matching testbeds.</span>) : null}</p>

            <div className="testbed-cards">
                {filteredTestbeds.map(tb => (<TestbedCard key={tb.uid} activeFilters={activeFilters}
                                                          allTestbedTechnologies={technologies} {...tb} />))}
            </div>
        </Fragment>);

}
