import React, {useState, Fragment, createContext, useContext} from "react";
import {observer} from "mobx-react";
import {useRouteMatch} from "react-router-dom";
import ResourceFilters from "./ResourceFilters";
import ExploreResourcesService from "../api/ExploreResources";
import {useQuery} from 'react-query';

import Table from "rc-table";
import "rc-tree/assets/index.css";

const explore_service = new ExploreResourcesService();

const ExploreResources = observer((props) => {
    const {categories} = props;

    const availableFilters = {
        categories: Object.keys(categories).map(catId => {
            return {id: catId, name: categories[catId].name};
        }),
        features: []
    };

    const [selectedFilters, setSelectedFilters] = useState({category: "wired", features: []})

    const resourcesQueryEnabled = selectedFilters['features'].length > 0;
    const {
        data: resources,
        isLoading,
        isError,
        status: resourcesQueryStatus,
    } = useQuery(['broker', selectedFilters],

        () => {
            const filtersForRequest = {...selectedFilters};

            if (filtersForRequest['features'].length === 0) {
                filtersForRequest['features'] = categories[filtersForRequest['category']]['features'].map((f) => f.id);
            }
            return explore_service.getResourceCounts(filtersForRequest);
        },
        {
            enabled: resourcesQueryEnabled
        }
    );

    const updateSelectedFilters = (key, newFilters) => setSelectedFilters({...selectedFilters, [key]: newFilters});


    const matchByTestbed = useRouteMatch('/by_testbed/:testbed_id');
    const matchedTestbed = matchByTestbed && matchByTestbed.params['testbed_id'] || undefined;
    const matchByCategory = useRouteMatch('/by_category/:category');
    const matchedCategory = matchByCategory && matchByCategory.params['category'] || undefined;

    if (selectedFilters['category'] !== matchedCategory) {
        setSelectedFilters({...selectedFilters, category: matchedCategory, features: []})
    }

    if (selectedFilters['testbed_id'] !== matchedTestbed) {
        setSelectedFilters({...selectedFilters, testbed_id: matchedTestbed});
    }

    let categoryFriendlyName;

    if (matchedCategory && matchedCategory in categories) {
        const cat = categories[matchedCategory];

        categoryFriendlyName = cat['name'];
        availableFilters['features'] = cat['features'] || [];

    } else {
        categoryFriendlyName = "All";

        const allFeatures = new Map();
        Object.values(categories).forEach(cat => {
            if ('features' in cat) {
                for (const feature of cat['features']) {
                    allFeatures.set(feature.id, feature);
                }
            }
        })
        availableFilters['features'] = [...allFeatures.values()];
    }

    const filterFriendlyNames = {};
    availableFilters['features'].forEach(f => filterFriendlyNames[f.id] = f.name);


    const columns = [{
        title: 'Hardware Type',
        dataIndex: 'hardware_type',
        key: 'hardware_type',
        render: (text, row, index) => {

            let missingFeaturesWarning = null;

            if (row.missing_features && row.missing_features.length > 0) {
                const missingFilterFriendlyNames = row.missing_features.map(mf => filterFriendlyNames[mf] || mf);
                missingFeaturesWarning = (<i className="fal fa-exclamation-triangle alert has-tip right"
                                             data-tooltip
                                             title={`The following features are missing: ${missingFilterFriendlyNames.join(', ')}`}
                />);
            }


            return (<span>{text} {missingFeaturesWarning}</span>);
        }
    },
        {
            title: "Count",
            dataIndex: 'count', 'key': 'count'
        },
        {title: 'CPU', dataIndex: 'cpu_ghz', key: 'cpu'},
        {title: 'RAM', dataIndex: 'ram_gb', key: 'ram'},
        {title: 'Disk', dataIndex: 'disk_gb', key: 'disk'},
    ];

    const columns_with_domain = [{title: "Domain", dataIndex: 'domain', key: 'domain'}, ...columns];

    // const expandedRowRenderer = (record) => <p style={{margin: 12}}>
    //     {/*<strong>Description: </strong> {record['description']}<br/>*/}
    //     <strong>Nodes: </strong> node01, node02, node05</p>;
    //
    // const expandIcon = (props) =>
    //     (<a onClick={e => {
    //         props.onExpand(props.record, e);
    //     }}>
    //         <i className={`fal ${props.expanded ? 'fa-minus-square' : 'fa-plus-square'}`}
    //            aria-hidden="true"/>
    //     </a>);

    let resourcesPerTestbed = null;

    if (isError) {
        resourcesPerTestbed = (
            <div className="callout alert">An error occurred while fetching the matching resources. Please try again
                later</div>);

    } else if (resources) {
        console.log({resources});
        const testbedsWithResources = [...new Set(resources.map(r => r['testbed_id']))];
        testbedsWithResources.sort();

        resourcesPerTestbed = testbedsWithResources.map(tbId => {
            const filteredResources = resources.filter(r => r['testbed_id'] === tbId);

            console.log(tbId, filteredResources);

            const domains = new Set(filteredResources.map(r => r.domain));

            return (<div className="card testbed-card" key={tbId}>
                <div className="card-section">
                    <h3>{filteredResources[0]['testbed_friendlyName']}</h3>

                    <Table columns={domains.size > 1 ? columns_with_domain : columns}
                           data={filteredResources}
                           rowKey={record => record['testbed_id'] + record['domain'] + record['hardware_type']}
                        // expandable={{
                        //     rowExpandable: false, //record => record['description'].length > 0,
                        //     expandRowByClick: true,
                        //     expandedRowRender: expandedRowRenderer,
                        //     expandIcon: expandIcon
                        // }}
                    />
                </div>
            </div>)
        });
    }

    return (
        <Fragment>
            <aside id="sidebar-filters" className="filters-aside">
                <ResourceFilters filters={availableFilters}
                                 activeFilters={selectedFilters}
                                 updateFilters={updateSelectedFilters}/>
            </aside>

            <main className="main-content">
                <div className="main-content-wrapper">

                    <h2>Find resources in {categoryFriendlyName} testbeds</h2>
                    <p>Use the filters to discover which resources match your requirements.</p>

                    <div className={`resources-per-testbed resources-per-testbed-${resourcesQueryStatus}`}>

                        {!resourcesQueryEnabled ?
                            <div className="callout secondary">Please select at least one feature to get results</div> : null}

                        {isLoading ?
                            <div id="loading-overlay">
                                <div className="lds-grid">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div> : null}

                        <div className="testbed-cards">
                            {resourcesPerTestbed}
                        </div>
                    </div>

                </div>
            </main>
        </Fragment>);

});

export default function ExploreResourcesPage(props) {
    const {categories} = props;
    //  resourcesStore.setCategories(categories);

    return (<ExploreResources categories={categories}/>);
}
