import React, {Component} from 'react';
import {useHistory} from "react-router-dom";
import Tree from 'rc-tree';
import {CATEGORY_ID_TO_NAME} from "./ExploreTestbeds";
import {SIZE_TO_TEXT} from './TestbedCard';

export default function TestbedFilters(props) {

    const {filters, activeFilters, updateFilters, testbeds} = props;
    const {technologies, properties, locations} = filters;

    const history = useHistory();

    const updateCategory = (e) => {
        if (e.target.checked) {
            if (e.target.value !== activeFilters['category']) {
                history.push(`/${e.target.value}`);
            }
        }
    }

    const techTree = [];
    const parents = {};
    for (const tech of technologies) {
        const tbCount = testbeds.filter(tb => tb.properties['technologies'].includes(tech.key)).length;

        if (tech.parent) {
            if (!(tech.parent in parents)) {
                const parent = {key: tech.parent, title: tech.parent, children: []};
                parents[tech.parent] = parent;
                techTree.push(parent);
            }
            parents[tech.parent].children.push(
                {
                    key: tech.key,
                    title: (<span>{tech.name} (<small>{tbCount}</small>)</span>)
                });
        } else {
            techTree.push(
                {
                    key: tech.key,
                    title: (<span>{tech.name} (<small>{tbCount}</small>)</span>),
                    children: []
                });
        }
    }

    const onShowFederatedChange = (event) => {
        updateFilters({'show_federated': event.target.value === "show-all" ? null : event.target.value === "federated-only"});

    };


    console.log("Showing tree with data ", techTree, " and filters", activeFilters);

    return (<div>
        <h4>Category</h4>

        <div style={{backgroundColor: 'white', padding: '12px 0 12px 12px', marginBottom: 24}}>
            {Object.keys(CATEGORY_ID_TO_NAME).map(category => (<span key={category}>
                    <input type="radio" id={category}
                           checked={activeFilters['category'] === category}
                           value={category} onChange={updateCategory}></input>
                            <label htmlFor={category}>{CATEGORY_ID_TO_NAME[category]}
                                <small> ({testbeds.filter(tb => tb.properties['category'].includes(category)).length})</small></label><br/></span>
                )
            )}

            <input type="radio" id="all" checked={!activeFilters['category'] ||
            activeFilters['category'] === "all"} onChange={updateCategory} value=""></input>
            <label htmlFor="all">Show All <small>({testbeds.length})</small></label>
        </div>

        <h4>Filters</h4>
        <div className="filter">

            <h6>Technologies</h6>
            <div style={{backgroundColor: 'white', padding: '12px 0 12px 12px'}}>
                <Tree treeData={techTree}
                      onCheck={(checkedTechnologies) => updateFilters({'technologies': checkedTechnologies})}
                      checkedKeys={activeFilters['technologies']}
                      checkable={<span className="zf-tree-checkbox-inner"/>}
                      showIcon={false}
                      switcherIcon={(props) => {
                          const {isLeaf, expanded, loading} = props;
                          if (isLeaf) {
                              return null;
                          } else if (expanded) {
                              return <span className="zf-tree-switcher-icon"><i
                                  className="fal fa-caret-down"></i></span>;
                          } else {
                              return <span className="zf-tree-switcher-icon"><i
                                  className="fal fa-caret-right"></i></span>;
                          }
                      }}
                      selectable={false}
                      prefixCls="zf-tree"/>

                {/*{technologies.map(t => (<span key={t}>*/}
                {/*    <input type="checkbox" id={t} checked={activeFilters['technologies'].includes(t)}*/}
                {/*           onChange={e => {*/}
                {/*               const checked = e.target.checked;*/}
                {/*               if (checked) {*/}
                {/*                   updateFilters({*/}
                {/*                       'technologies': [t, ...activeFilters['technologies']]*/}
                {/*                   });*/}
                {/*               } else {*/}
                {/*                   updateFilters({*/}
                {/*                       'technologies': activeFilters['technologies'].filter(active_t => active_t != t)*/}
                {/*                   });*/}
                {/*               }*/}
                {/*           }}*/}

                {/*    ></input>*/}
                {/*            <label htmlFor={t}>{t}*/}
                {/*                <small> ({testbeds.filter(tb => tb.properties['technologies'].includes(t)).length})</small></label><br/></span>*/}
                {/*    )*/}
                {/*)}*/}
            </div>
        </div>

        <div className="filter">

            <h6>Properties</h6>
            <div style={{backgroundColor: 'white', padding: '12px 0 12px 12px'}}>
                {properties.map(prop => (<span key={prop}>
                    <input type="checkbox" id={prop}
                           checked={activeFilters['properties'].includes(prop)}
                           onChange={e => {
                               const checked = e.target.checked;
                               if (checked) {
                                   updateFilters({'properties': [prop, ...activeFilters['properties']]});
                               } else {
                                   updateFilters({
                                       'properties': activeFilters['properties'].filter(active_prop => active_prop != prop)
                                   });
                               }
                           }}></input>
                               <label htmlFor={prop}>{prop}
                                   <small> ({testbeds.filter(tb => tb.properties['properties'].includes(prop)).length})</small>
                               </label><br/></span>
                    )
                )}
            </div>
        </div>

        <div className='filter'>
            <h6>Federated</h6>

            <div style={{backgroundColor: 'white', padding: '12px 0 12px 12px'}}>
                <input name="federated" type="radio" onChange={onShowFederatedChange}
                value="show-all" checked={activeFilters['show_federated'] === null}></input>
                <label htmlFor="show-all">Show All</label><br/>
                <input name="federated" type="radio" onChange={onShowFederatedChange}
                 value="federated-only" checked={activeFilters['show_federated'] === true}></input>
                 <label htmlFor="show-all">Federated Only <small> ({testbeds.filter(tb => tb.is_federated ).length})</small></label><br/>
                <input name="federated" type="radio" onChange={onShowFederatedChange}
                value="non-federated-only" checked={activeFilters['show_federated'] === false}></input>
                <label htmlFor="show-all">Non-federated Only <small> ({testbeds.filter(tb => !tb.is_federated).length })</small></label>
            </div>
        </div>

        <div className="filter">

            <h6>Location</h6>

            <div style={{backgroundColor: 'white', padding: '12px 0 12px 12px'}}>
                {locations.map(loc => (<span key={loc}>
                               <input type="checkbox" id={loc}
                                      checked={activeFilters['locations'].includes(loc)}
                                      onChange={e => {
                                          const checked = e.target.checked;
                                          if (checked) {
                                              updateFilters({'locations': [loc, ...activeFilters['locations']]});
                                          } else {
                                              updateFilters({
                                                  'locations': activeFilters['locations'].filter(active_loc => active_loc != loc)
                                              });
                                          }
                                      }}></input>
                               <label htmlFor={loc}>{loc}
                                   <small> ({testbeds.filter(tb => tb.country === loc).length})</small></label><br/></span>
                    )
                )}
            </div>
        </div>

        <div className="filter">

            <h6>Size</h6>

            <div style={{backgroundColor: 'white', padding: '12px 0 12px 12px'}}>
                {Object.entries(SIZE_TO_TEXT).map(([id,name]) => (<span key={id}>
                               <input type="checkbox" id={id}
                                      checked={activeFilters['sizes'].includes(id)}
                                      onChange={e => {
                                          const checked = e.target.checked;
                                          if (checked) {
                                              updateFilters({'sizes': [id, ...activeFilters['sizes']]});
                                          } else {
                                              updateFilters({
                                                  'sizes': activeFilters['sizes'].filter(active_id => active_id != id)
                                              });
                                          }
                                      }}></input>
                               <label htmlFor={id}>{name}
                                   <small> ({testbeds.filter(tb => tb.properties.size === id).length})</small></label><br/></span>
                    )
                )}
            </div>
        </div>
    </div>);
}
